<style lang="scss" scoped>
.page-form {
  margin: 0 !important;
  padding: 15px;
  .el-col {
    height: 100%;
    overflow: auto;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    h1 {
      font-size: 18px;
      color: #333;
      margin-bottom: 24px;
    }
  }
}
.form_self_item {
  width: 100%;
  padding: 10px 0 10px 0;
  overflow: hidden;
  .comname {
    width: 140px;
    text-align: right;
    font-size: 15px;
    color: #333333;
    float: left;
  }
  .comcurrent {
    float: left;
    margin-left: 10px;
    text-align: left;
    min-width: 500px;
    &.files {
      display: flex;
      align-items: flex-start;
      .sys {
        margin-top: 8px;
      }
    }
    .sys {
      display: inline-block;
      border: 1px solid #1989fa;
      color: #1989fa;
      font-size: 14px;
      padding: 0 5px;
      margin-left: 10px;
      height: 24px;
      line-height: 24px;
    }
    .el-icon-edit,
    .el-icon-delete {
      color: #409eff;
      font-size: 25px;
      margin-left: 10px;
      cursor: pointer;
      margin-top: 3px;
      opacity: 0.9;
      &:hover {
        opacity: 1;
      }
      &:active {
        font-size: 23px;
      }
    }
    .el-icon-delete {
      color: #f56c6c;
    }
  }
}
.time-line {
  box-sizing: border-box;
  padding: 0 0 20px 20px;
  .el-timeline {
    padding-left: 36px;
    h4 {
      font-weight: 500;
      color: #333;
      line-height: 28px;
    }
    p {
      color: #666;
      font-weight: lighter;
      line-height: 24px;
    }
  }
}
::v-deep .el-card__body {
  padding: 6px 20px 10px;
}
</style>

<template>
  <!-- 用户新增/编辑 -->
  <section class="page-child-contianer" v-if="formData.side">
    <el-row class="page-form" :gutter="40">
      <el-col :span="10">
        <h1>工单详情</h1>
        <el-form :model="formData" ref="formData" label-width="150px">
          <el-form-item label="工单标题:" prop="user_info">
            {{ formData.main.name }}
          </el-form-item>

          <el-form-item label="填报人员:" prop="user_info">
            {{ formData.side.content.user_info }}
          </el-form-item>

          <el-form-item label="项目:" prop="project_name">
            {{ formData.side.content.project_name }}
          </el-form-item>

          <el-form-item label="物业单位:" prop="company_name">
            {{ formData.side.content.company_name }}
          </el-form-item>
          <el-form-item label="工单名称:" prop="work_order_name">
            {{ formData.main.work_order_name }}
          </el-form-item>
          <el-form-item label="状态:" prop="status_name">
            {{ formData.main.status_name }}
          </el-form-item>
          <el-form-item label="发布人:" prop="issue_user_name">
            {{ formData.main.issue_user_name }}
          </el-form-item>
          <el-form-item label="申请时间:" prop="apply_time">
            {{ (formData.main.apply_time * 1000) | formatTime("YYYY-MM-DD HH:mm") }}
          </el-form-item>
          <el-form-item label="当前待办事项/人:"  v-if="formData.dealt.length">
            <div>
              <div
              v-for="item in formData.dealt"
              :key="item.id"
              >
                     {{item.name}}-{{item.user_name}}【{{item.mobile}}】
              </div>
            </div>
          </el-form-item>
          <!-- 自定义表单数据[start] -->
          <template v-for="(item, index) in progressFormComList">
            <el-form-item
              :key="index"
              v-if="item.field_name != 'id'"
              :label="item.name + ':'"
              class="form_self_item"
            >
              <div class="comcurrent" v-if="item.type != 'file'">
                <p style="margin-top: 0px; width: 95%">
                  {{ item.default_value }} <span class="sys">自定义字段</span>
                </p>
              </div>
              <div class="comcurrent files" v-if="item.type == 'file' && item.file_list">
                <template v-for="(citem, cindex) in item.file_list">
                  <a target="_blank" :href="citem.path_source" :key="cindex">
                    <img
                      v-if="citem.mime_type.indexOf('image/') >= 0"
                      :src="citem.path_source"
                      style="width: 200px"
                    />
                    <span v-else> {{ citem.name }}</span>
                  </a>
                </template>
                <span class="sys">自定义字段</span>
              </div>
            </el-form-item>
          </template>
          <!-- 自定义表单数据[end] -->
        </el-form>
      </el-col>
      <el-col :span="7" v-if="formData.audit.length">
        <div class="time-line">
          <h1>工单动态</h1>
          <el-timeline reverse>
            <el-timeline-item
              v-for="item in formData.audit"
              :key="item.id"
              :timestamp="item.create_time_text"
              placement="top"
            >
              <el-card>
                <h3 style="font-family: 黑体;">{{item.step_name}}</h3>
                <h4>{{ item.do_result_name }}</h4>
                <p>处理人员：{{ item.issue_user_name }}</p>
                <p v-if="item.do_result_name">处理结果：{{ item.do_result_name }}</p>
                <p v-if="item.desc">备注说明：{{ item.desc }}</p>
                <div v-if="item.annex_list.length">
                  <p>完成凭证：</p>
                  <el-row :gutter="10">
                    <el-col :span="6" v-for="img in item.annex_list" :key="img.id">
                      <a target="_blank" :href="img.path_source">
                        <img :src="img.path_source" style="width: 100%" />
                      </a>
                    </el-col>
                  </el-row>
                </div>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
      </el-col>
      <el-col :span="7" v-if="formData.comment.length">
        <div class="time-line">
          <h1>工单评论</h1>
          <el-timeline reverse>
            <el-timeline-item
              v-for="item in formData.comment"
              :key="item.id"
              :timestamp="item.create_time_text"
              placement="top"
            >
              <el-card>
                <h4>{{ item.do_result_name }}</h4>
                <p>评论人员：{{ item.issue_user_name }}</p>
                <p>满意度：{{ item.star_name }}</p>
                <div>
                  <p>评论内容：{{ item.content || "无" }}</p>
                  <el-row :gutter="10" v-if="item.annex_list.length">
                    <el-col :span="6" v-for="img in item.annex_list" :key="img.id">
                      <a target="_blank" :href="img.path_source">
                        <img :src="img.path_source" style="width: 100%" />
                      </a>
                    </el-col>
                  </el-row>
                </div>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
      </el-col>
    </el-row>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { workOrderApplyDetail } from "@api/workOrderApply";
import { workOrderProgressFormList } from "@api/workOrderProgress";
import { formatTime } from "@utils";
export default {
  name: "staffForm",
  inject: ["reload"],
  /**
   * 组件
   */
  components: {},
  data() {
    return {
      id: null, //修改用户id
      //动态表单数据
      progressFormComList: [],
      imageUrl: "", //图片上传成功回显链接
      formData: {
        checkList: [],
        status: 2,
        position_rank: 3,
      }, //表单存储数据
    };
  },
  computed: {},
  async created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.getDetail();
    }
  },
  mounted() {},
  methods: {
    /** 修改前获取详情信息 */
    async getDetail() {
      /**
       * 请求接口获取详情数据
       */
      let res = await workOrderApplyDetail(this.id);
      res.data.status = Number(res.data.status);
      res.data.password = "";
      if (res.data.comment.length) {
        res.data.comment.map(
          (item) => (item.create_time_text = formatTime(item.create_time * 1000))
        );
      }
      if (res.data.audit.length) {
        res.data.audit.map(
          (item) => (item.create_time_text = formatTime(item.create_time * 1000))
        );
      }
      this.formData = {
        ...this.formData,
        ...res.data,
      };
      this.imageUrl = res.data.thumb_path;
      //请求表单数据
      if (this.formData.side && this.formData && this.formData.side.content) {
        this.progressFormComList = (
          await workOrderProgressFormList({
            work_order_id: this.formData.side.content.work_order_id,
          })
        ).data;
        for (let k in this.formData.side.extend) {
          if (this.progressFormComList.filter((a) => a.field_name == k).length > 0)
            this.progressFormComList.filter((a) => a.field_name == k)[0].default_value =
              this.formData.side.extend[k] || "无数据";

          if (
            this.progressFormComList.filter((a) => a.field_name == k)[0] &&
            this.progressFormComList.filter((a) => a.field_name == k)[0].type == "file"
          ) {
            if (
              this.formData.side.extend[k + "_list"] &&
              this.formData.side.extend[k + "_list"].length > 0
            )
              this.progressFormComList.filter((a) => a.field_name == k)[0][
                "file_list"
              ] = this.formData.side.extend[k + "_list"];
            else
              this.progressFormComList.filter((a) => a.field_name == k)[0][
                "file_list"
              ] = [];
          }
          if (
            this.progressFormComList.filter((a) => a.field_name == k)[0] &&
            this.progressFormComList.filter((a) => a.field_name == k)[0].type ==
              "datetime"
          ) {
            this.progressFormComList.filter(
              (a) => a.field_name == k
            )[0].default_value = formatTime(
              this.formData.side.extend[k] * 1000,
              "YYYY-MM-DD HH:mm"
            );
          }
        }
      }
      //请求返回成功后打开弹窗
      this.formDialogVisible = true;
    },
    /** 取消用户编辑 */
    handleCancle() {
      this.$refs["formData"].clearValidate();
      this.$router.go(-1);
    },

    /** 上传前处理 */
    uploadBefore(file) {
      const isImg =
        file.type == "image/jpeg" ||
        file.type == "image/jpg" ||
        file.type == "image/png" ||
        file.type == "image/gif";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isImg) {
        this.$message.error("上传图片只能是 .jpg,.jpeg,.png,.gif 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isImg && isLt2M;
    },
    /** 上传成功 */
    uploadSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.formData = {
        ...this.formData,
        thumb: res.data.list[0].id,
      };
    },
    /** 上传失败 */
    uploadError(err, file) {
      this.$message.warning("上传失败");
    },
  },
};
</script>
